@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  background: linear-gradient(135deg, #0FA958 0%, #0FA958 100%);
  font-family: 'Poppins', sans-serif;
  color: #333;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 20px;
  cursor: url('./Cursor.svg'), auto;
}

.App {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  cursor: url('./Cursor.svg'), auto;
}

.counter {
  font-size: 72px;
  font-weight: 700;
  color: #4a4a4a;
  text-align: center;
  margin-bottom: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: url('./Cursor.svg'), auto;
}

.sol-price {
  text-align: center;
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
  cursor: url('./Cursor.svg'), auto;
}

.increment-control {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  cursor: url('./Cursor.svg'), auto;
}

.increment-control input {
  width: 80px;
  padding: 10px;
  font-size: 18px;
  border: 2px solid #ddd;
  border-radius: 10px;
  text-align: center;
  cursor: url('./Cursor.svg'), auto;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
  cursor: url('./Cursor.svg'), auto;
}

.pay-button, .click-button, .reset-button {
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: url('./Cursor.svg'), auto;
}

.pay-button {
  background-color: #222fec;
  color: white;
  cursor: url('./Cursor.svg'), auto;
}

.click-button {
  background-color: #0FA958; /* Bright green color */
  color: white;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 0 #000000; /* Darker green for 3D effect */
  transition: all 0.1s ease;
}

.click-button:hover {
  background-color: #0FA958; /* Slightly lighter green on hover */
}

.click-button:active {
  transform: translateY(4px);
  box-shadow: 0 0 0 #005a24;
}

.reset-button {
  background-color: #FF5722;
  color: white;
  cursor: url('./Cursor.svg'), auto;
}

.pay-button:hover, .click-button:hover, .reset-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  cursor: url('./Cursor.svg'), auto;
}

.wallet-stats {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
  padding: 20px;
  margin-top: 30px;
  text-align: center;
  cursor: url('./Cursor.svg'), auto;
}

.wallet-stats p {
  margin: 10px 0;
  font-size: 16px;
  cursor: url('./Cursor.svg'), auto;
}

.WalletMultiButton {
  display: block;
  margin: 30px auto 0;
  cursor: url('./Cursor.svg'), auto;
}
.wallet-stats input[type="text"] {
  width: 200px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: url('./Cursor.svg'), auto;
}

.wallet-stats label {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: url('./Cursor.svg'), auto;
}

.wallet-stats input[type="checkbox"] {
  margin-right: 10px;
  cursor: url('./Cursor.svg'), auto;
}