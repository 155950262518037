.leaderboard {
  margin-top: 30px;
}

.leaderboard table {
  width: 100%;
  border-collapse: collapse;
}

.leaderboard th, .leaderboard td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.leaderboard th {
  background-color: #f2f2f2;
  cursor: pointer;
}

.leaderboard th:hover {
  background-color: #ddd;
}

.leaderboard tr:nth-child(even) {
  background-color: #f9f9f9;
}

.leaderboard tr:hover {
  background-color: #f5f5f5;
}